<template>
  <div>
    <p>
      <button v-on:click="list(1)" class="btn btn-white btn-default btn-round">
        <i class="ace-icon fa fa-refresh"></i>
        刷新
      </button>
    </p>

    <table id="simple-table" class="table  table-bordered table-hover">
      <thead>
      <tr>
        <th>id</th>
        <th>住院号</th>
        <th>姓名</th>
        <th>支付金额</th>
        <th>支付状态</th>
        <th>订单创建时间</th>
        <th>支付完成时间</th>
        <th>操作员</th>
        <th>操作</th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="log in logs">
        <td>{{log.id}}</td>
        <td>{{log.zy_num}}</td>
        <td>{{log.realname}}</td>
        <td>{{log.price}}</td>
        <td>
          <span v-if="log.status === 1">待支付</span>
          <span v-else-if="log.status === 2">已支付</span>
          <span v-else-if="log.status === -1">支付失败</span>
          <span v-else-if="log.status === -2">用户取消支付</span>
          <span v-else-if="log.status === 3">完成充值</span>
          <span v-else>未知状态</span>
        </td>
        <td>{{log.create_time}}</td>
        <td>{{log.placed_time}}</td>
        <td>{{log.operator}}</td>
        <td>
          <div class="hidden-sm hidden-xs btn-group">
            <button v-show="hasResource('021202')" v-if="log.status === 2" v-on:click="finishPay(log)" class="btn btn-xs btn-info">
              充值提交
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <pagination ref="pagination" v-bind:list="list" v-bind:itemCount="8"></pagination>

  </div>
</template>

<script>
import Pagination from "../../components/pagination";
export default {
  components: {Pagination},
  name: "system-paylog",
  data: function() {
    return {
      log: {},
      logs: [],
    }
  },
  mounted: function() {
    let _this = this;
    _this.$refs.pagination.size = 20;
    _this.list(1);
    // sidebar激活样式方法一
    // this.$parent.activeSidebar("system-user-sidebar");
  },
  methods: {
    /**
     * 查找是否有权限
     * @param id
     */
    hasResource(id) {
      return Tool.hasResource(id);
    },

    /**
     * 列表查询
     */
    list(page) {
      let _this = this;
      Loading.show();
      _this.$ajax.post(process.env.VUE_APP_SERVER + 'pay_log/zy_list', {
        page: page,
        size: _this.$refs.pagination.size,
      }).then((response)=>{
        console.log("列表查询结果：", response);
        Loading.hide();
        let resp = response.data;
        if (resp.success) {
          _this.logs = resp.content.list;
          _this.$refs.pagination.render(page, resp.content.total);
        }
      })
    },
    finishPay(log) {
      let _this = this;
      Loading.show();
      _this.$ajax.get(process.env.VUE_APP_SERVER + 'pay_log/finish_pay/' + log.id).then((response)=>{
        Loading.hide();
        let resp = response.data;
        if (resp.success) {
          Toast.success("操作成功！");
          _this.list(1);
        }
      })
    }
  }
}
</script>
