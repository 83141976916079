<template>
  <div>
    <div style="margin-bottom: 10px; display: flex; flex-direction: row; align-items: center;">
      <button v-on:click="list()" class="btn btn-white btn-default btn-round" style="margin-right: 16px;">
        <i class="ace-icon fa fa-refresh"></i>
        刷新
      </button>
      姓名：<input v-model="doctor_name_key" class="form-control" style="width: 100px; margin-right: 10px;">
      <button v-on:click="list(1)" class="btn btn-white btn-default btn-round" style="margin-left: 6px;">
        <i class="ace-icon fa fa-refresh"></i>
        检索
      </button>
    </div>

    <table id="simple-table" class="table  table-bordered table-hover">
      <thead>
      <tr>
        <th>id</th>
        <th class="detail-col">展开</th>
        <th>原数据名称</th>
        <th>姓名</th>
        <th>头像</th>
        <th>所属科室</th>
        <th>职称</th>
        <th>今日预约量</th>
        <th>7天预约量</th>
        <th>是否配置了排班</th>
      </tr>
      </thead>

      <tbody>
      <template v-for="(item, index) in doctor_items">
        <tr>
          <td>{{ item.id }}</td>
          <td class="el-col-2" @click="loadDetail(index, item.id)">
            <div class="action-buttons" style="display: flex; justify-content: center;">
              <a href="#" class="green bigger-140 show-details-btn" title="显示排班详情">
                <i class="ace-icon fa fa-angle-double-down"></i>
                <span class="sr-only">Details</span>
              </a>
            </div>
          </td>
          <td>{{ item.id_and_name_and_section }}</td>
          <td>{{ item.name }}</td>
          <td>
            <img :src="item.avatar" style="width: 100px;">
          </td>
          <td>{{ item.section_name }}</td>
          <td>{{ item.positional }}</td>
          <td>{{ item.today_order_num }}</td>
          <td>{{ item.seven_day_order_num }}</td>
          <td v-if="item.has_arrange" style="color: green;">已配置</td>
          <td v-else><span style="color: red;">未配置</span></td>
        </tr>
        <tr class="" style="background-color: #F1F6F8" v-show="index == currentActive">
          <td colspan="8">
            <div class="table-detail">
              <div class="row">
                <div class="col-xs-12 col-sm-2">
                  <div class="text-center">
                    <img height="150" class="thumbnail inline no-margin-bottom" alt="医生头像" :src="item.avatar"/>
                    <br/>
                    <div class="width-80 label label-info label-xlg arrowed-in arrowed-in-right">
                      <div class="inline position-relative">
                        <a class="user-title-label" href="#">
                          <i class="ace-icon fa fa-circle light-green"></i>
                          <span class="white">{{ item.name }}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-xs-12 col-sm-5">
                  <div class="space visible-xs"></div>
                  <div class="profile-user-info profile-user-info-striped">
                    <div class="profile-info-row" v-for="(item2, index2) in doctorArrangeSimpleList">
                      <div class="profile-info-name" style="width: 80px"> {{ index2 + 1 }}</div>
                      <div class="profile-info-value">
                        <span>{{ item2.week }} {{ item2.monthDay }}</span>
                        <span>{{ item2.hasStr }}</span>
                        <span>已预约 {{ item2.dayOrderedCount }} 次</span>
                        <span>剩余 {{ item2.dayUnOrderedCount }} 次</span>
                        <span>共 {{ item2.dayTotalCount }} 次</span>
                      </div>
                      <el-button size="mini" v-on:click="changeOne(item, item2, index2)">查看与调整</el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </template>
      </tbody>
    </table>
    <pagination ref="pagination" v-bind:list="list" v-bind:itemCount="8"></pagination>

    <div id="form-modal" class="modal fade" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document" style="width: 1000px;">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">【{{ doctor_item.name }}】【{{ doctor_arrange_item.monthDay }}】预约调整</h4>
          </div>
          <div class="modal-body">
            <form class="form-horizontal">
<!--              <el-checkbox :indeterminate="isMorningIndeterminate" v-model="morningCheckAll" @change="handleMorningCheckAllChange">全选</el-checkbox>-->
              <span style="line-height: 32px;">【上午】</span>
              <el-checkbox-group v-model="morningChecked">
                <el-checkbox v-for="(item, index) in doctor_arrange_items_morning" :label="item.id" :key="item.id">
                  <span>时段：[<font style="color: blue;">{{ item.time_start }} - {{ item.time_end }}</font>]</span>
                  <span style="margin-left: 6px;">预约号：[<font style="color: blue;">{{ item.register_num }}</font>]</span>
                  <span style="margin-left: 10px;">预约情况：[
                  <font v-if="item.ordered === 0" style="color: #999999;">未被预约</font>
                  <font v-else style="color: blue;">{{item.ordered_username_and_phone}}</font>
                  ]</span>
                  <span style="margin-left: 6px;">状态：[
                  <font v-if="item.status === 1" style="color: #00B83F;">启用</font>
                  <font v-if="item.status === 0" style="color: red">停用</font>
                  ]</span>
                  <span> -- ID:[{{item.id}}]</span>
                </el-checkbox>
              </el-checkbox-group>
              <div style="margin-bottom: 10px;"></div>
<!--              <el-checkbox :indeterminate="isAfternoonIndeterminate" v-model="afternoonCheckAll" @change="handleAfternoonCheckAllChange">全选</el-checkbox>-->
              <span style="line-height: 32px;">【下午】</span>
              <el-checkbox-group v-model="afternoonChecked">
                <el-checkbox v-for="(item, index) in doctor_arrange_items_afternoon" :label="item.id" :key="item.id">
                  <span>时段：[<font style="color: blue;">{{ item.time_start }} - {{ item.time_end }}</font>]</span>
                  <span style="margin-left: 6px;">预约号：[<font style="color: blue;">{{ item.register_num }}</font>]</span>
                  <span style="margin-left: 10px;">预约情况：[
                  <font v-if="item.ordered === 0" style="color: #999999;">未被预约</font>
                  <font v-else style="color: blue;">{{item.ordered_username_and_phone}}</font>
                  ]</span>
                  <span style="margin-left: 6px;">状态：[
                  <font v-if="item.status === 1" style="color: #00B83F;">启用</font>
                  <font v-if="item.status === 0" style="color: red">停用</font>
                  ]</span>
                  <span> -- ID:[{{item.id}}]</span>
                </el-checkbox>
              </el-checkbox-group>
            </form>
          </div>
          <div class="modal-footer">
            <button v-on:click="useArrange()" type="button" class="btn btn-primary">启用</button>
            <button v-on:click="stopUserArrange()" type="button" class="btn btn-primary">停用</button>
            <button v-on:click="copyArrange()" type="button" class="btn btn-primary">复制</button>
          </div>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
  </div>
</template>

<script>
import Pagination from "@/components/pagination.vue";

export default {
  name: "setting-arrange",
  components: {Pagination},
  data: function () {
    return {
      doctor_name_key: "",
      doctor_item: {},
      doctor_items: [],
      doctor_arrange_item: {},
      doctor_arrange_index: 0,
      doctor_arrange_items_morning: [],
      doctor_arrange_items_afternoon: [],
      currentActive: -1,
      checkedWeekDays: [],
      isMorningIndeterminate: false,
      isAfternoonIndeterminate: false,
      morningCheckAll: false,
      afternoonCheckAll: false,
      doctorArrangeSettingData: {
        time_space: "",
        morning_start_time: "",
        morning_end_time: "",
        afternoon_start_time: "",
        afternoon_end_time: "",
      },
      doctorArrangeSimpleList: [],
      checked: [],
      morningChecked: [],
      afternoonChecked: []
    }
  },
  mounted: function () {
    let _this = this;
    _this.$refs.pagination.size = 5;
    _this.list(1);

    // sidebar激活样式方法一
    // this.$parent.activeSidebar("system-user-sidebar");
  },
  methods: {
    /**
     * 查找是否有权限
     * @param id
     */
    hasResource(id) {
      return Tool.hasResource(id);
    },

    /**
     * 列表查询
     */
    list(page) {
      let _this = this;
      Loading.show();
      _this.$ajax.post(process.env.VUE_APP_SERVER + 'doctor/list', {
        page: page,
        size: _this.$refs.pagination.size,
        name: _this.name
      }).then((response) => {
        console.log("医生列表查询结果：", response);
        Loading.hide();
        let resp = response.data;
        if (resp.success) {
          _this.doctor_items = resp.content.list;
          _this.$refs.pagination.render(page, resp.content.total);
        }
      })
    },

    loadDetail(index, id) {
      // console.log("index", index)
      const _this = this;
      if (this.currentActive == index) {
        this.currentActive = -1;
      } else {
        this.currentActive = index;
      }
      _this.getArrangeSimpleListByDoctorId(id)
    },

    getArrangeSimpleListByDoctorId(doctorId) {
      const _this = this;
      Loading.show();
      _this.$ajax.get(process.env.VUE_APP_SERVER + 'arrange/get_doctor_arrange_list/' + doctorId).then((response) => {
        Loading.hide();
        _this.doctorArrangeSimpleList = response.data.content;
      })
    },

    changeOne(item, item2, index2) {
      let _this = this;
      this.morningCheckAll = false
      _this.doctor_item = $.extend({}, item);
      // console.log("_this.doctor_item", _this.doctor_item)
      // console.log("item2", item2)
      // console.log("index2", index2)
      _this.doctor_arrange_item = $.extend({}, item2);
      _this.doctor_arrange_index = $.extend({}, index2);
      _this.$ajax.get(process.env.VUE_APP_SERVER + 'arrange/time_quantum_list/' + item.id + "/" + (index2 + 1)).then((response) => {
        Loading.hide();
        let resp = response.data;
        if (resp.success) {
          _this.doctor_arrange_items_morning = resp.content.morning
          _this.doctor_arrange_items_afternoon = resp.content.afternoon
        }
      })
      $("#form-modal").modal("show");
    },
    useArrange () {
      const _this = this;
      Loading.show();
      const checked = _this.morningChecked.concat(_this.afternoonChecked)
      _this.$ajax.post(process.env.VUE_APP_SERVER + 'arrange/all_use', {
        checked_list: checked
      }).then((response)=>{
        Loading.hide();
        let resp = response.data;
        if (resp.success) {
          $("#form-modal").modal("hide");
          _this.list(1);
          Toast.success("批量啟用成功！");
          _this.checked = []
          _this.morningChecked = []
          _this.afternoonChecked = []
        }
      })
      // console.log("checkedMorning", this.checked)
    },
    stopUserArrange () {
      const _this = this;
      Loading.show();
      const checked = _this.morningChecked.concat(_this.afternoonChecked)
      _this.$ajax.post(process.env.VUE_APP_SERVER + 'arrange/all_stop_use', {
        checked_list: checked
      }).then((response)=>{
        Loading.hide();
        let resp = response.data;
        if (resp.success) {
          $("#form-modal").modal("hide");
          _this.list(1);
          Toast.success("批量停用成功！");
          _this.checked = []
          _this.morningChecked = []
          _this.afternoonChecked = []
        }
      })
    },
    copyArrange () {
      const _this = this;
      Loading.show();
      const checked = _this.morningChecked.concat(_this.afternoonChecked)
      _this.$ajax.post(process.env.VUE_APP_SERVER + 'arrange/all_copy', {
        checked_list: checked
      }).then((response)=>{
        Loading.hide();
        let resp = response.data;
        if (resp.success) {
          $("#form-modal").modal("hide");
          _this.list(1);
          Toast.success("批量拷貝成功！");
          _this.checked = []
          _this.morningChecked = []
          _this.afternoonChecked = []
        }
      })
    },
    // handleMorningCheckAllChange(val) {
    //   // this.morningChecked = val ? this.all_week_day_checked : [];
    //   console.log("handleMorningCheckAllChange", val)
    //   this.isMorningIndeterminate = false;
    // },
    // handleAfternoonCheckAllChange(val) {
    //   console.log("handleAfternoonCheckAllChange", val)
    //   // this.checkedWeekDays = val ? this.all_week_day_checked : [];
    //   this.isAfternoonIndeterminate = false;
    // },
    // handleCheckedMorningChange(val) {
    //   console.log("handleCheckedMorningChange", val)
    // },
    // handleCheckedAfternoonChange(val) {
    //   console.log("handleCheckedAfternoonChange", val)
    // }
  }
}
</script>
