import Vue from 'vue'
import App from './app.vue'
import router from './router'
import axios from 'axios'
import filter from './filter/filter'
import { Button, Select, TimeSelect, InputNumber, Checkbox, CheckboxGroup, Option } from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false;
Vue.prototype.$ajax = axios;

Vue.component(Button.name, Button);
Vue.component(Select.name, Select);
Vue.component(Option.name, Option);

Vue.use(TimeSelect);
Vue.use(InputNumber);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);

// 解决每次ajax请求，对应的sessionId不一致的问题
axios.defaults.withCredentials = true;

/**
 * axios拦截器
 */
axios.interceptors.request.use(function (config) {
  console.log("请求：", config);
  let token = Tool.getLoginUser().token;
  if (Tool.isNotEmpty(token)) {
    config.headers.Authorization = "Bearer " + token;
    console.log("请求headers增加token:", token);
  }
  return config;
}, error => {});
axios.interceptors.response.use(function (response) {
  console.log("返回结果：", response);
  return response;
}, error => {
  console.log("返回拦截：", error.response);
  let response = error.response;
  const status = response.status;
  const firstNum = (status + '').substring(0,1);
  // 如果http状态码是以2开头，请求通过
  if (firstNum === '2') {
    console.log("以2开头的http状态码：", status);
    console.log("返回结果：", response);
    return response;
  }
  if (status === 401) {
    // 判断状态码是401 跳转到登录
    console.log("未登录，跳到登录页面");
    Tool.setLoginUser(null);
    router.push('/login');
  }
  if (status === 417) {
    // 判断状态码是401 跳转到登录
    console.log("哈哈，操作被拦截了，你就当操作成功了！示例网站暂不开放增删改操作");
    return {
      data: {
        success: false,
        message: "哈哈，操作被拦截了，你就当操作成功了！示例网站暂不开放增删改操作"
      }
    };
  }
  // 其他以3、4、5开头的状态码
  if (firstNum === '3' || firstNum === '4' || firstNum === '5') {
    // 判断状态码是401 跳转到登录
    console.log("这个请求是其他以3、4、5开头的状态码");
    console.log('response.data.message', response.data.message);
    Toast.error("保存失败！" + response.data.message);
    return {
      data: {
        success: false,
        message: response.data.message
      }
    };
  }
  return {
    data: {
      success: false,
      message: "请重新登录"
    }
  };
});

// 全局过滤器
Object.keys(filter).forEach(key => {
  Vue.filter(key, filter[key])
});

// 路由登录拦截
router.beforeEach((to, from, next) => {
  // 要不要对meta.loginRequire属性做监控拦截
  if (to.matched.some(function (item) {
    return item.meta.loginRequire
  })) {
    let loginUser = Tool.getLoginUser();
    if (Tool.isEmpty(loginUser)) {
      next('/login');
    } else {
      next();
    }
  } else {
    next();
  }
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');

console.log("环境：", process.env.NODE_ENV);
