<template>
  <div class="no-skin">
    <div id="navbar" class="navbar navbar-default ace-save-state">
      <div class="navbar-container ace-save-state" id="navbar-container">
        <button type="button" class="navbar-toggle menu-toggler pull-left" id="menu-toggler" data-target="#sidebar" @click="addMobilMenu()">
          <span class="sr-only">Toggle sidebar</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>

        <div class="navbar-header pull-left">
          <div class="navbar-brand">
            <small>
              <i class="fa fa-leaf"></i>
              玉环洪福堂康复医院 · 小程序后台管理系统
            </small>
          </div>
        </div>

        <div class="navbar-buttons navbar-header pull-right" role="navigation">
          <ul class="nav ace-nav">
            <li class="light-blue dropdown-modal">
              <a data-toggle="dropdown" href="#" class="dropdown-toggle">
                <img class="nav-user-photo" src="../../public/ace/assets/images/avatars/user.jpg" alt="Jason's Photo"/>

                <i class="ace-icon fa fa-caret-down"></i>
              </a>

              <ul class="user-menu dropdown-menu-right dropdown-menu dropdown-yellow dropdown-caret dropdown-close">
                <li>
                  <a href="#">
                    <i class="ace-icon fa fa-cog"></i>
                    系统设置
                  </a>
                </li>

                <li>
                  <a href="profile.html">
                    <i class="ace-icon fa fa-user"></i>
                    个人信息
                  </a>
                </li>

                <li class="divider"></li>

                <li>
                  <a v-on:click="logout()" href="#">
                    <i class="ace-icon fa fa-power-off"></i>
                    退出登录
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div><!-- /.navbar-container -->
    </div>

    <div class="main-container ace-save-state" id="main-container">
      <div id="sidebar" class="sidebar responsive ace-save-state" :class="addMenuDisplay === true ? 'display' : ''">
        <ul class="nav nav-list">
          <li class="" id="welcome-sidebar">
            <router-link to="/welcome">
              <i class="menu-icon fa fa-tachometer"></i>
              <span class="menu-text"> 欢迎：{{loginUser.nickname}} </span>
            </router-link>
            <b class="arrow"></b>
          </li>

          <li v-show="hasResource('01')" class="">
            <a href="#" class="dropdown-toggle">
              <i class="menu-icon fa fa-globe"></i>
              <span class="menu-text"> 系统管理 </span>
              <b class="arrow fa fa-angle-down"></b>
            </a>

            <b class="arrow"></b>

            <ul class="submenu">
              <li v-show="hasResource('0101')" class="" id="system-user-sidebar">
                <router-link to="/system/user">
                  <i class="menu-icon fa fa-caret-right"></i>
                  管理员管理
                </router-link>
                <b class="arrow"></b>
              </li>
              <li v-show="hasResource('0102')" class="" id="system-resource-sidebar">
                <router-link to="/system/resource">
                  <i class="menu-icon fa fa-caret-right"></i>
                  资源管理
                </router-link>
                <b class="arrow"></b>
              </li>
              <li v-show="hasResource('0103')" class="" id="system-role-sidebar">
                <router-link to="/system/role">
                  <i class="menu-icon fa fa-caret-right"></i>
                  角色管理
                </router-link>
                <b class="arrow"></b>
              </li>
            </ul>
          </li>

          <li v-show="hasResource('02')" class="">
            <a href="#" class="dropdown-toggle">
              <i class="menu-icon fa fa-coffee"></i>
              <span class="menu-text"> 业务管理 </span>
              <b class="arrow fa fa-angle-down"></b>
            </a>

            <b class="arrow"></b>

            <ul class="submenu">
              <li v-show="hasResource('0201')" class="" id="business-banner-sidebar">
                <router-link to="/business/banner">
                  <i class="menu-icon fa fa-caret-right"></i>
                  轮播图管理
                </router-link>
                <b class="arrow"></b>
              </li>
              <li v-show="hasResource('0202')" class="" id="business-category-sidebar">
                <router-link to="/business/category">
                  <i class="menu-icon fa fa-caret-right"></i>
                  科室分类管理
                </router-link>
                <b class="arrow"></b>
              </li>
              <li v-show="hasResource('0203')" class="" id="business-section-sidebar">
                <router-link to="/business/section">
                  <i class="menu-icon fa fa-caret-right"></i>
                  科室管理
                </router-link>
                <b class="arrow"></b>
              </li>
              <li v-show="hasResource('0204')" class="" id="business-doctor-sidebar">
                <router-link to="/business/doctor">
                  <i class="menu-icon fa fa-caret-right"></i>
                  医生管理
                </router-link>
                <b class="arrow"></b>
              </li>
              <li v-show="hasResource('0205')" class="" id="business-advise-sidebar">
                <router-link to="/business/advise">
                  <i class="menu-icon fa fa-caret-right"></i>
                  投诉建议管理
                </router-link>
                <b class="arrow"></b>
              </li>
              <li v-show="hasResource('0206')" class="" id="business-contact-sidebar">
                <router-link to="/business/contact">
                  <i class="menu-icon fa fa-caret-right"></i>
                  联系我们管理
                </router-link>
                <b class="arrow"></b>
              </li>
              <li v-show="hasResource('0207')" class="" id="business-meeting-sidebar">
                <router-link to="/business/meeting">
                  <i class="menu-icon fa fa-caret-right"></i>
                  视频探视管理
                </router-link>
                <b class="arrow"></b>
              </li>
              <li v-show="hasResource('0208')" class="" id="business-message-sidebar">
                <router-link to="/business/message">
                  <i class="menu-icon fa fa-caret-right"></i>
                  家属留言管理
                </router-link>
                <b class="arrow"></b>
              </li>
              <li v-show="hasResource('0209')" class="" id="business-medicine-sidebar">
                <router-link to="/business/medicine">
                  <i class="menu-icon fa fa-caret-right"></i>
                  煎药管理
                </router-link>
                <b class="arrow"></b>
              </li>
              <li v-show="hasResource('0210')" class="" id="business-mzuser-sidebar">
                <router-link to="/business/mzuser">
                  <i class="menu-icon fa fa-caret-right"></i>
                  门诊病人管理
                </router-link>
                <b class="arrow"></b>
              </li>
              <li v-show="hasResource('0211')" class="" id="business-zyuser-sidebar">
                <router-link to="/business/zyuser">
                  <i class="menu-icon fa fa-caret-right"></i>
                  住院病人管理
                </router-link>
                <b class="arrow"></b>
              </li>
              <li v-show="hasResource('0212')" class="" id="business-paylog-sidebar">
                <router-link to="/business/paylog">
                  <i class="menu-icon fa fa-caret-right"></i>
                  住院缴费管理
                </router-link>
                <b class="arrow"></b>
              </li>
              <li v-show="hasResource('0213')" class="" id="business-order-sidebar">
                <router-link to="/business/order">
                  <i class="menu-icon fa fa-caret-right"></i>
                  挂号预约管理
                </router-link>
                <b class="arrow"></b>
              </li>
              <li v-show="hasResource('0214')" class="" id="business-shop-sidebar">
                <router-link to="/business/shop">
                  <i class="menu-icon fa fa-caret-right"></i>
                  门店管理
                </router-link>
                <b class="arrow"></b>
              </li>
              <li v-show="hasResource('0215')" class="" id="business-shopuser-sidebar">
                <router-link to="/business/shopuser">
                  <i class="menu-icon fa fa-caret-right"></i>
                  门店病人管理
                </router-link>
                <b class="arrow"></b>
              </li>
              <li v-show="hasResource('0216')" class="" id="business-shoporder-sidebar">
                <router-link to="/business/shoporder">
                  <i class="menu-icon fa fa-caret-right"></i>
                  门店预约管理
                </router-link>
                <b class="arrow"></b>
              </li>
              <li v-show="hasResource('0217')" class="" id="business-shopuseradmin-sidebar">
                <router-link to="/business/shopuseradmin">
                  <i class="menu-icon fa fa-caret-right"></i>
                  门店管理员管理
                </router-link>
                <b class="arrow"></b>
              </li>
            </ul>
          </li>

          <li v-show="hasResource('04')" class="">
            <a href="#" class="dropdown-toggle">
              <i class="menu-icon fa fa-fire"></i>
              <span class="menu-text"> 孕检管理 </span>
              <b class="arrow fa fa-angle-down"></b>
            </a>

            <b class="arrow"></b>

            <ul class="submenu">
              <li v-show="hasResource('0401')" class="" id="yj-yj-sidebar">
                <router-link to="/yj/yj">
                  <i class="menu-icon fa fa-caret-right"></i>
                  孕检点管理
                </router-link>
                <b class="arrow"></b>
              </li>
              <li v-show="hasResource('0402')" class="" id="yj-user-sidebar">
                <router-link to="/yj/user">
                  <i class="menu-icon fa fa-caret-right"></i>
                  孕检病人管理
                </router-link>
                <b class="arrow"></b>
              </li>
              <li v-show="hasResource('0403')" class="" id="yj-order-sidebar">
                <router-link to="/yj/order">
                  <i class="menu-icon fa fa-caret-right"></i>
                  孕检预约管理
                </router-link>
                <b class="arrow"></b>
              </li>
              <li v-show="hasResource('0404')" class="" id="yj-useradmin-sidebar">
                <router-link to="/yj/useradmin">
                  <i class="menu-icon fa fa-caret-right"></i>
                  孕检管理员管理
                </router-link>
                <b class="arrow"></b>
              </li>

              <li v-show="hasResource('0405')" class="" id="yj-arrange-sidebar">
                <router-link to="/yj/arrange">
                  <i class="menu-icon fa fa-caret-right"></i>
                  孕检点排班配置管理
                </router-link>
                <b class="arrow"></b>
              </li>

              <li v-show="hasResource('0406')" class="" id="yj-recommender-sidebar">
                <router-link to="/yj/recommender">
                  <i class="menu-icon fa fa-caret-right"></i>
                  孕检推荐人管理
                </router-link>
                <b class="arrow"></b>
              </li>
            </ul>
          </li>

          <li v-show="hasResource('05')" class="">
            <a href="#" class="dropdown-toggle">
              <i class="menu-icon fa fa-cutlery"></i>
              <span class="menu-text"> 食堂管理 </span>
              <b class="arrow fa fa-angle-down"></b>
            </a>

            <b class="arrow"></b>

            <ul class="submenu">
              <li v-show="hasResource('0501')" class="" id="food-food-sidebar">
                <router-link to="/food/food">
                  <i class="menu-icon fa fa-caret-right"></i>
                  菜品管理
                </router-link>
                <b class="arrow"></b>
              </li>
              <li v-show="hasResource('0502')" class="" id="food-user-sidebar">
                <router-link to="/food/user">
                  <i class="menu-icon fa fa-caret-right"></i>
                  用户管理
                </router-link>
                <b class="arrow"></b>
              </li>
              <li v-show="hasResource('0503')" class="" id="food-order-sidebar">
                <router-link to="/food/order">
                  <i class="menu-icon fa fa-caret-right"></i>
                  订单管理
                </router-link>
                <b class="arrow"></b>
              </li>
              <li v-show="hasResource('0504')" class="" id="food-xcx-useradmin-sidebar">
                <router-link to="/food/xcx_useradmin">
                  <i class="menu-icon fa fa-caret-right"></i>
                  小程序管理员管理
                </router-link>
                <b class="arrow"></b>
              </li>
              <li v-show="hasResource('0505')" class="" id="food-app-useradmin-sidebar">
                <router-link to="/food/app_useradmin">
                  <i class="menu-icon fa fa-caret-right"></i>
                  App 管理员管理
                </router-link>
                <b class="arrow"></b>
              </li>
            </ul>
          </li>

          <li v-show="hasResource('03')" class="">
            <a href="#" class="dropdown-toggle">
              <i class="menu-icon fa fa-cogs"></i>
              <span class="menu-text"> 配置管理 </span>
              <b class="arrow fa fa-angle-down"></b>
            </a>

            <b class="arrow"></b>

            <ul class="submenu">
              <li v-show="hasResource('0301')" class="" id="setting-arrange-sidebar">
                <router-link to="/setting/arrange">
                  <i class="menu-icon fa fa-caret-right"></i>
                  排班配置管理
                </router-link>
                <b class="arrow"></b>
              </li>
              <li v-show="hasResource('0302')" class="" id="setting-zypaymoney-sidebar">
                <router-link to="/setting/zypaymoney">
                  <i class="menu-icon fa fa-caret-right"></i>
                  住院缴费额度
                </router-link>
                <b class="arrow"></b>
              </li>
              <li v-show="hasResource('0303')" class="" id="setting-arrangesb-sidebar">
                <router-link to="/setting/arrangesb">
                  <i class="menu-icon fa fa-caret-right"></i>
                  门店排班配置管理
                </router-link>
                <b class="arrow"></b>
              </li>
              <li v-show="hasResource('0310')" class="" id="setting-setting-sidebar">
                <router-link to="/setting/setting">
                  <i class="menu-icon fa fa-caret-right"></i>
                  其他配置管理
                </router-link>
                <b class="arrow"></b>
              </li>
            </ul>
          </li>
        </ul><!-- /.nav-list -->

        <div class="sidebar-toggle sidebar-collapse" id="sidebar-collapse">
          <i id="sidebar-toggle-icon" class="ace-icon fa fa-angle-double-left ace-save-state"
             data-icon1="ace-icon fa fa-angle-double-left" data-icon2="ace-icon fa fa-angle-double-right"></i>
        </div>
      </div>

      <div class="main-content">
        <div class="main-content-inner">
          <div class="page-content">
            <div class="row">
              <div class="col-xs-12">
                <!-- PAGE CONTENT BEGINS -->
                <router-view/>
                <!-- PAGE CONTENT ENDS -->
              </div><!-- /.col -->
            </div><!-- /.row -->
          </div><!-- /.page-content -->
        </div>
      </div><!-- /.main-content -->

      <div class="footer">
        <div class="footer-inner">
          <div class="footer-content">
						<span class="bigger-120">
							<span class="blue bolder">玉环洪福堂康复医院</span>
							小程序后台管理系统 &copy; 2021-2030
						</span>
          </div>
        </div>
      </div>

      <a href="#" id="btn-scroll-up" class="btn-scroll-up btn btn-sm btn-inverse">
        <i class="ace-icon fa fa-angle-double-up icon-only bigger-110"></i>
      </a>
    </div><!-- /.main-container -->
  </div>
</template>

<script>
  export default {
    name: "admin",
    data: function () {
      return {
        loginUser: {},
        addMenuDisplay: false
      }
    },
    mounted: function () {
      let _this = this;
      // $("body").removeClass("login-layout light-login");
      $('body').attr('class', 'login-layout');
      $("body").attr("class", "no-skin");
      // sidebar激活样式方法二
      _this.activeSidebar(_this.$route.name.replace("/", "-") + "-sidebar");

      $.getScript('/ace/assets/js/ace.min.js');

      _this.loginUser = Tool.getLoginUser();
      // console.log("loginUser", _this.loginUser);

      if (!_this.hasResourceRouter(_this.$route.name)) {
        _this.$router.push("/login");
      }
    },
    watch: {
      $route: {
        handler: function (val, oldVal) {
          // sidebar激活样式方法二
          console.log("---->页面跳转：", val, oldVal);
          let _this = this;

          if (!_this.hasResourceRouter(val.name)) {
            _this.$router.push("/login");
            return;
          }

          _this.$nextTick(function () {  //页面加载完成后执行
            _this.activeSidebar(_this.$route.name.replace("/", "-") + "-sidebar");
          })
        }
      }
    },
    methods: {
      addMobilMenu () {
        this.addMenuDisplay = !this.addMenuDisplay
      },
      /**
       * 查找是否有权限
       * @param router
       */
      hasResourceRouter(router) {
        let _this = this;
        let resources = Tool.getLoginUser().resources;
        if (Tool.isEmpty(resources)) {
          return false;
        }
        for (let i = 0; i < resources.length; i++) {
          if (router === resources[i].page) {
            return true;
          }
        }
        return false;
      },
      /**
       * 查找是否有权限
       * @param id
       */
      hasResource(id) {
        return Tool.hasResource(id);
      },

      login() {
        this.$router.push("/admin")
      },

      /**
       * 菜单激活样式，id是当前点击的菜单的id
       * @param id
       */
      activeSidebar: function (id) {
        console.log("选中的id:", id)
        // 兄弟菜单去掉active样式，自身增加active样式
        $("#" + id).siblings().removeClass("active");
        $("#" + id).siblings().find("li").removeClass("active");
        console.log("移除的元素：", $("#" + id).siblings())
        $("#" + id).addClass("active");

        // 如果有父菜单，父菜单的兄弟菜单去掉open active，父菜单增加open active
        let parentLi = $("#" + id).parents("li");
        if (parentLi) {
          parentLi.siblings().removeClass("open active");
          parentLi.siblings().find("li").removeClass("active");
          parentLi.addClass("open active");
        }
      },

      logout() {
        let _this = this;
        Loading.show();
        _this.$ajax.get(process.env.VUE_APP_SERVER + 'admin_user/logout/' + _this.loginUser.token).then((response) => {
          Loading.hide();
          let resp = response.data;
          if (resp.success) {
            Tool.setLoginUser(null);
            _this.$router.push("/login")
          } else {
            Toast.warning(resp.message)
          }
        });
      },
    }
  }
</script>
